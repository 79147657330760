import React from 'react'

const Loader = () => {
    return (
        <div class="loadingio-spinner-rolling-z68pyj1xkyl"><div class="ldio-9t92s4ef525">
            <div></div>
        </div></div>
    )
}

export default Loader